.panel__head {
	padding: 0;
	border: 0;
	cursor: pointer;
	background-color: transparent;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

.panel__body {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	transition: height 0.3s ease-in-out;
}

.panel__content {
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.panel[aria-expanded='true'] .panel__content {
	opacity: 1;
}
